function redirectToAppStore() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
        window.location.href = "https://play.google.com/store/apps/details?id=com.sumangaleechit";
    } else if (/iPad|iPhone|iPod/.test(userAgent) || (navigator.platform === 'MacIntel'
        && navigator.maxTouchPoints > 1
    )) {
        window.location.href = "https://apps.apple.com/in/app/amr-sumangalee-jewellers/id6448749147";
    }
}

redirectToAppStore();