import { useEffect, useState } from "react";
import tw from "twin.macro";
// import SliderImage from "react-zoom-slider";
import { likeCount, wishCount } from "../api/ProducDetailsApi";
import { useLocation, useNavigate, Link } from "react-router-dom";
import Noimg from "../images/no_img.jpg";
import { getProduct } from "api/ProductApi";
import { currentDate, filterFormatToDate, YEAR_WISE_FORMAT } from "utility/Constant";
import styled from "styled-components";
import sample_img from '../images/sample_img.jpg';
import sampleimg2 from '../images/sampleimg2.jpg'
import {
  getAddToCart,
  getBranchId,
  getCustomerId,
  setAddToCart,
} from "../utility/HeaderConfig.js";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import toast, { Toaster } from "react-hot-toast";
// import Pincode from "components/Pincode";
import BookAppointmentButton from "components/BookAppointmentButton";
import ProductPriceDetails from "components/ProductPriceDetails";
import { useCartContext } from "context/UseCartContext";
import RelatedProduct from "components/RelatedProduct";
import Magnifier from "../components/Magnifier";
// import ReactImageMagnify from 'react-image-magnify';
import { Carousel } from "react-responsive-carousel";
import { dayClose } from "api/NewSchemeApi";
import moment from "moment";
import { branchdetails } from "api/BranchDetailsApi";
import { ECommerce } from "api/AppConfigApi";

const StyledDiv = tw.div`font-display overflow-hidden md:overflow-visible `;

const AccordionButton = styled.button`
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: background-color 0.4s;

  &:hover {
    background-color: #ccc;
  }

  &.active {
    background-color: #ccc;
  }
`;

const Panel = styled.div`
  padding: 0 18px;
  background-color: white;
  display: ${(props) => (props.active ? "block" : "none")};
  overflow: hidden;
  transition: max-height 0.2s ease-out;
`;

export default ({ configImages }) => {
  const [Details, setDetails] = useState([]);
  const [otherCharge, setOtherCharge] = useState([]);
  const [productId, setProductId] = useState("");
  const [additionalCharge, setAdditionalCharge] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [Img, setImg] = useState([]);
  const [Spec, setSpec] = useState([]);
  const [LikeCount, setLikeCount] = useState("");
  const [WishCount, setWishCount] = useState("");
  const [banner, setBanner] = useState([]);
  const [currentImg, setCurrentImg] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [isActiveToggle, setIsActiveToggle] = useState(false);
  const [secMetalDetail, setSecMetalDetail] = useState([]);
  const [branch, setBranch] = useState({});
  const [isShow, setIsShow] = useState(false);


  // const [branchName, setBranchName ] = useState("");
  const history = useNavigate();
  const location = useLocation();

  const { dispatch } = useCartContext();

  useEffect(() => {
    // getProductIdParsing();
    getBanner();
    getBrachDataList()
    //eslint-disable-next-line
  }, []);

  const getBrachDataList = () => {
    branchdetails(getBranchId()).then((data) => {
      setBranch(data.response);
    })
  }

  useEffect(() => {
    getProductIdParsing();
    getAddToCartDetails();
    //eslint-disable-next-line
  }, [location.state])

  const getProductIdParsing = () => {
    let productId = "";
    if (!location.state) {
      const pathName = location.pathname.split("/");
      productId = pathName[2];
    } else {
      productId = location.state?.categoryItem?.id;
    }
    setProductId(productId);
    getRateApi(productId);
    likeCountDetails(productId);
    wishCountDetails(productId);
  };

  const getBanner = () => {
    configImages &&
      configImages.E_COMMERCE_BANNERS.forEach((data) => {
        if (data.key === "productDetails") {
          setBanner(data.value);
        }
      });
  };

  const getRateApi = async (productId) => {
    let allOtherCharges = [];
    let stoneChargeList = [];
    let secMetalDetails = [];
    let currentDate = moment().format(YEAR_WISE_FORMAT)
    let filter = {
      branch: getBranchId(),
      beforeCreatedAt: filterFormatToDate(currentDate),
      id: productId,
    };
    dispatch({ type: "DAYCLOSED", payload: filterFormatToDate(currentDate) });
    await getProduct(10, filter).then((data) => {
      let imageData = [];
      const productResponse = data.response.content[0];
      const imageResponse = data.response.content[0];
      if (
        imageResponse !== null &&
        imageResponse !== "" &&
        imageResponse !== "-"
      ) {
        const img = imageResponse.images;
        let productImg = img.split('@#@').filter(item => item !== '');
        setImg(productImg);
        setCurrentImg(productImg[0]);
      } else {
        imageData.push({
          image: Noimg,
        });
        // setImg(imageData);
        setCurrentImg(Noimg);
      }
      setSpec(data.response.prodSpecMappingList ?? '');
      let otherChargeJSON = JSON.parse(data.response.content[0]?.otherCharges);
      let secMetalJSON = JSON.parse(data.response.content[0]?.secMetalDetails);
      secMetalJSON.forEach((item) => {
        secMetalDetails.push(item);
      });
      let datas = otherChargeJSON.filter((item) => {
        if (
          item.remarks === "stonecharge" ||
          item.remarks === "handlingcharge" ||
          item.remarks === "stampingcharge" ||
          item.remarks === "additionalcharge" ||
          item.remarks === "freightCharges"
        ) {
          return item;
        }
      });
      allOtherCharges = datas;
      setAdditionalCharge(allOtherCharges);
      otherChargeJSON.forEach((otherChargeItem) => {
        if ({}.hasOwnProperty.call(otherChargeItem, "stone")) {
          if (otherChargeItem.stone.length > 0) {
            stoneChargeList = otherChargeItem.stone;
          }
        }
      });

      let Rate = data.response.content[0];
      let ProductDetail = productResponse;
      let Certificate;
      JSON.parse(ProductDetail.otherCharges).map((value) => {
        if (value.stone && value.stone.length > 0) {
          Certificate = value.stone[0].certificatenumber;
        }
      });
      const weight = JSON.parse(ProductDetail.weight)
      setDetails({
        ...ProductDetail,
        ...Rate,
        //  ...BasePrice,
        Certificate,
        weight,
      });
      setLoader(true);
      setOtherCharge(stoneChargeList);
      setSecMetalDetail(secMetalDetails);
    });
  };

  const likeCountDetails = async (countId) => {
    try {
      const data = await likeCount(countId);
      const likeData = data.response;
      setLikeCount(likeData > 0 ? likeData : 0);
    } catch (error) {
      console.error("Error fetching like count:", error);
      setLikeCount(0);
    }
  };

  const wishCountDetails = async (countId) => {
    try {
      const data = await wishCount(countId);
      const wishData = data.response;
      setWishCount(wishData > 0 ? wishData : 0);
    } catch (error) {
      console.error("Error fetching wish count:", error);
      setWishCount(0);
    }
  };

  const addToCart = (productCode) => {
    const productData = JSON.parse(
      getAddToCart() !== undefined ? getAddToCart() : "[]"
    );
    let products = [];
    products = productData;
    if (products.length < 3 && products.length !== 3) {
      products.push(Details);
      let storeCardItem = JSON.parse(
        getAddToCart() !== undefined ? getAddToCart() : "[]"
      );
      const validateProduct = storeCardItem.some(
        (item) => item.productCode === productCode
      );

      if (!validateProduct) {
        setAddToCart(JSON.stringify(products));
        // setTimeout(() => {
        dispatch({ type: "ADD", payload: JSON.parse(getAddToCart()) });
        // }, 0);
        toast.success("Product Added To The Cart", {
          duration: 2000,
          position: "top-right",
        });
      } else {
        toast.error("Product Already Added To The Cart !", {
          duration: 3000,
          position: "top-right",
        });
      }
    } else {
      toast.error(
        "Cart Limit Reached Your Cart Can Only Contain Up To Three Items !",
        {
          duration: 3000,
          position: "top-right",
        }
      );
    }
  };

  const buynow = (productCode) => {
    const productData = JSON.parse(
      getAddToCart() !== undefined ? getAddToCart() : "[]"
    );
    let products = [];
    products = productData;
    if (products.length < 3 && products.length !== 3) {
      products.push(Details);
      let storeCardItem = JSON.parse(
        getAddToCart() !== undefined ? getAddToCart() : "[]"
      );
      const validateProduct = storeCardItem.some(
        (item) => item.productCode === productCode
      );
      if (!validateProduct) {
        if (getCustomerId()) {
          setAddToCart(JSON.stringify(products));
          dispatch({ type: "ADD", payload: JSON.parse(getAddToCart()) });
          history("/CheckoutPage", { state: { Details } });
        } else {
          dispatch({ type: "MODAL", payload: "login" });
        }
      } else {
        if (getCustomerId()) {
          history("/CheckoutPage", { state: { Details } });
        } else {
          dispatch({ type: "MODAL", payload: "login" });
        }
      }
    } else {
      toast.error(
        "Cart Limit Reached Your Cart Can Only Contain Up To Three Items !",
        {
          duration: 3000,
          position: "top-right",
        }
      );
    }
  };

  const thumb = (index) => {
    setCurrentImg(Img[index]);
  };

  const toggleAccordion = () => {
    setIsActive(!isActive);
  };

  const toggleAccordionToggle = () => {
    setIsActiveToggle(!isActiveToggle);
  };

  const getAddToCartDetails = async () => {
    let filter = 'savings_scheme_app';
    ECommerce(filter).then((data) => {
      let showButton = JSON.parse(data.response.moduleField);
      let isShowData = showButton.filter(item => item.key === "IS_ECOMM_ADD_TO_CART_ENABLED")
        setIsShow(isShowData[0].value);
    });
  }

  return (
    <StyledDiv>
      <Toaster position="top-right" />
      {/* {banner && banner.length > 0 ? (
        <img src={banner} alt="Jewellery Product Details" className="w-100" />
      ) : (
        <Skeleton tw="w-full" style={{ height: "200px" }} />
      )} */}
      {/* <div className="px-sm-5 px-0" tw="text-sm pt-3">
        <Link to="/" tw="mx-3 no-underline text-black">
          Home
        </Link>
        /
        <span tw="ml-3 text-custom-100 capitalize tracking-wide ">
          {Details.productName?.toLowerCase()}
        </span>
      </div> */}
      <section className="py-sm-5 py-3 position-relative" tw=" my-2 sm:my-10">
        <div className="container-lg">
          <div className="row">
            {isLoader ? (
              <>
                <div className="col-md-4">
                  <Magnifier
                    imgSrc={currentImg}
                    imgWidth={`100%`}
                    imgHeight={`100%`}
                    magnifierRadius={120}
                  />
                  <Carousel
                    emulateTouch={true}
                    swipeable={true}
                    interval={5000}
                    transitionTime={1000}
                    onClickThumb={(idx) => thumb(idx)}
                    className="role"
                  >
                    {Img.map((data) => (
                      <div>
                        <img alt='slider-image' src={data} />
                      </div>
                    ))}
                  </Carousel>
                </div>
                <div className="col-md-8" tw="sm:py-0 py-10">
                  <h2 tw="pb-2 uppercase">{Details.productName}</h2>
                  <div className="row">
                    <table tw="leading-9" className="col-md-12 col-lg-6">
                      <tr tw="mb-3">
                        <td tw="px-2"> Product Code</td>:
                        <td tw="px-2">{Details.productCode}</td>
                      </tr>
                      <tr>
                        <td tw="px-2">Product Type</td>:
                        <td tw="px-2">
                          {Details.productTypeName}
                          {Details.productTypeName?.toLowerCase() ===
                            "silver" ? null : (
                            <div className="badge" tw="bg-yellow-600 p-1" style={{ marginLeft: '5px' }}>
                              {" "}
                              {Details.weight.purity}
                            </div>
                          )}
                        </td>
                      </tr>
                      {Details.brand !== "" ? (
                        <tr>
                          <td tw="px-2">Brand</td>:
                          <td tw="px-2">{Details.brand}</td>
                        </tr>
                      ) : null}
                    </table>
                    <div className="col-md-6">
                      <div className="pt-2">
                        <i className="bi bi-heart"></i> {WishCount} Wish
                        <i className="bi bi-hand-thumbs-up ms-3"></i>{" "}
                        {LikeCount} Like
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-lg-6 col-xl-5">
                      <div className="py-2">
                        {Details.stockStatus.toLowerCase() === 'sold' ? '' : (
                          <>
                            <h3 className="price ">
                              <span tw="text-base">Price :</span> ₹{" "}
                              {Math.trunc(Details.total)}
                            </h3>
                            <small>*Inclusive of all taxes</small>
                          </>
                        )}
                      </div>
                      {Details.status === "Sale" ? (
                        <div tw="text-green-600 py-1">
                          <h6 tw="mb-1">
                            <span>
                              Available at {Details.branchName} Branch
                            </span>
                          </h6>
                        </div>
                      ) : null}
                      {Details.Certificate > 0 && (
                        <p tw="text-sm my-2">
                          Certificate No : {Details.Certificate}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6  col-lg-6 col-xl-7">
                      <h6 tw="text-custom-100  mt-3 mb-3">
                        Weight Details (gms)
                      </h6>
                      <table className="table table-bordered mb-2">
                        <thead>
                          <tr>
                            <th>G.WT</th>
                            <th>L.WT</th>
                            <th>N.WT</th>
                          </tr>
                        </thead>
                        <tbody className="border-top-0 ">
                          <tr>
                            <td>{Details?.weight?.grossWeight}</td>
                            <td>{Details?.weight?.lessWeight}</td>
                            <td>{Details?.weight?.netWeight}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <p tw="text-sm  leading-7" className="line-clamp ">
                    {Details.description !== null &&
                      Details.description !== "-" &&
                      Details.description !== ""
                      ? Details.description
                      : ""}
                  </p>
                  {Details.status !== "Sale" ? (
                    <p tw="text-sm  leading-7" className="line-clamp ">
                      <span style={{ color: "red" }}>
                        {" "}
                        <i class="bi bi-exclamation-circle"></i> Temporarily Out
                        of Stock :
                      </span>{" "}
                      You can still order this !
                    </p>
                  ) : null}
                  {Details.pieceRate && Details.pieceRate !== "" ? (
                    <div className="row">
                      <div className="col mb-3">
                        Pcs Rate : <span>{Details.pieceRate}</span>
                      </div>
                    </div>
                  ) : null}
                  {Details.stockStatus.toLowerCase() === 'sold' ? '' : (
                    <>
                      {Details.wastageCost &&
                        Details.wastageInPer &&
                        Details.mcValue &&
                        Details.mcType !== "" ? (
                        <div className="container">
                          <div className="row">
                            <div className="col">
                              <b>Wastage</b>
                            </div>
                            <div className="col">
                              <b>Mc</b>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <p>
                                {/* {Details.wastageCost && Details.wastageInPer} % */}
                                {` ${Details.wastageValue}${Details.wastageType === "percentage"
                                  ? "%"
                                  : Details.wastageType === "pieces"
                                    ? "pcs"
                                    : Details.wastageType === "rs"
                                      ? "rs"
                                      : Details.wastageType === "mg"
                                        ? "gm"
                                        : Details.wastageType
                                  } `}{" "}
                              </p>
                            </div>
                            <div className="col">
                              <p>
                                ₹{" "}
                                {Details.netMc}
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </>
                  )}
                  {additionalCharge && additionalCharge.length > 0 ? (
                    <>
                      <AccordionButton
                        onClick={toggleAccordion}
                        className={isActive ? "active" : ""}
                      >
                        Additional Charges
                      </AccordionButton>
                      <Panel active={isActive}>
                        <div className="d-flex justify-content-center mt-3">
                          <h5>
                            {" "}
                            {(additionalCharge && additionalCharge.length > 0
                              ? additionalCharge[0]?.remarks
                              : ""
                            ).toUpperCase()}{" "}
                          </h5>
                        </div>
                        <hr
                          style={{
                            backgroundColor: "red",
                            height: "2px",
                            margin: "0px",
                          }}
                        />
                        <div className="container">
                          <div className="row">
                            <div className="col">
                              <b>Charge Type</b>
                            </div>
                            <div className="col">
                              <b>Piece</b>
                            </div>
                            <div className="col">
                              <b>Charge</b>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <p className="w-50">
                                {" "}
                                {additionalCharge && additionalCharge.length > 0
                                  ? additionalCharge[0]?.type
                                  : ""}
                              </p>
                            </div>
                            <div className="col">
                              <p className="w-50">
                                {" "}
                                {additionalCharge && additionalCharge.length > 0
                                  ? additionalCharge[0]?.piece
                                  : ""}
                              </p>
                            </div>
                            <div className="col">
                              <p className="w-50">
                                {additionalCharge && additionalCharge.length > 0
                                  ? additionalCharge[0]?.charge
                                  : ""}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Panel>
                    </>
                  ) : null}
                  {secMetalDetail && secMetalDetail.length > 0 ? (
                    <>
                      <AccordionButton
                        onClick={toggleAccordionToggle}
                        className={isActiveToggle ? "active" : ""}
                      >
                        Secondary Metal
                      </AccordionButton>
                      <Panel active={isActiveToggle}>
                        <div className="d-flex justify-content-center mt-3">
                          <h5>
                            {" "}
                            {secMetalDetail && secMetalDetail.length > 0
                              ? secMetalDetail[0]?.remarks
                              : ""}{" "}
                          </h5>
                        </div>
                        <div className="container">
                          <div className="row">
                            <div className="col">
                              <b> Metal Type :</b>{" "}
                              <span>
                                {secMetalDetail && secMetalDetail.length > 0
                                  ? secMetalDetail[0]?.productTypeName
                                  : ""}
                              </span>
                            </div>
                            <div className="col">
                              <b> Purity : </b>{" "}
                              <span>
                                {secMetalDetail && secMetalDetail.length > 0
                                  ? secMetalDetail[0]?.purityName
                                  : ""}
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <b> Halmarked :</b>{" "}
                              <span>
                                {secMetalDetail && secMetalDetail.length > 0
                                  ? secMetalDetail[0]?.standardName
                                  : ""}
                              </span>
                            </div>
                            <div className="col">
                              <b> Piece :</b>{" "}
                              <span>
                                {secMetalDetail && secMetalDetail.length > 0
                                  ? secMetalDetail[0]?.piece
                                  : ""}
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <b>Net Weight : </b>{" "}
                              <span>
                                {secMetalDetail && secMetalDetail.length > 0
                                  ? secMetalDetail[0]?.weight?.netWeight
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Panel>
                    </>
                  ) : null}
                  {otherCharge && otherCharge.length > 0 ? (
                    <>
                      <div className="mt-2">
                        <h4>Stone Details :</h4>
                        <hr style={{ backgroundColor: "red", height: "2px" }} />
                        <div className="d-flex justify-content-between">
                          <table className="table table-bordered mb-2">
                            <thead>
                              <tr>
                                <th>Material Type</th>
                                <th>Pieces</th>
                                <th>Type</th>
                                <th>Weight</th>
                                <th>Total Cost</th>
                              </tr>
                            </thead>
                            <tbody className="border-top-0" >
                              {otherCharge.map((item, index) => (
                                <tr key={index}>
                                  <td>{item.dataName}</td>
                                  <td>{item.pieces}</td>
                                  <td>{item.typeName}</td>
                                  <td>{item.grm}</td>
                                  <td>{item.totalValue}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  ) : null}
                  <div className="row">
                    {Spec.length > 0 && (
                      <div className="col-md-4">
                        <h6 tw="text-custom-100  mb-5">
                          Specification Details
                        </h6>

                        <table tw="leading-9" className="col-md-6 w-100">
                          {Spec.map((data, index) => (
                            <tr tw="mb-3" key={index}>
                              <td tw="px-3">
                                {data.specificationName.split("_")[0]}{" "}
                              </td>{" "}
                              :
                              <td tw="px-3">
                                {data.specificationValue !== "NA"
                                  ? data.specificationValue
                                  : "-"}
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    )}
                    <div
                      className={`${Spec.length > 0 ? "col-md-8" : "col-md-12"}`}
                    >
                      <ProductPriceDetails
                        Details={Details}
                        secMetalDetail={secMetalDetail}
                      />
                    </div>
                  </div>
                  <div className="row py-4">
                    {Details.status !== "Sale" ? (
                      <BookAppointmentButton category={Details} />
                    ) : (
                      <>
                        {/* <Pincode /> */}
                        <BookAppointmentButton category={Details} />
                        {!!isShow && (
                          <>
                            <div className=" col-12 col-lg-4 my-lg-0 my-3">
                              <div id="holder">
                                <div
                                  className="button"
                                  onClick={() => addToCart(Details.productCode)}
                                >
                                  <p className="btnText m-0 h-100 d-flex justify-content-center align-items-center rounded">
                                    Add To Cart
                                  </p>
                                  <div className="btnTwo">
                                    <p className="btnText2">
                                      <i className="bi bi-cart-plus"></i>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className=" col-12 col-lg-4">
                              <div id="holder">
                                <div
                                  className="button"
                                  onClick={() => buynow(Details.productCode)}
                                >
                                  <p className="btnText m-0 h-100 d-flex justify-content-center align-items-center rounded">
                                    Buy now
                                  </p>
                                  <div className="btnTwo">
                                    <p className="btnText2">
                                      <i className="bi bi-cash"></i>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-md-6">
                  <Skeleton tw="w-full h-full" />
                </div>
                <div className="col-md-6">
                  <Skeleton tw="pb-2 uppercase w-3/4 sm:h-1/12 h-0 mt-2 mb-0" />
                  <div className="row mx-2">
                    <table tw="leading-9" className="col-md-6">
                      <tr tw="mb-3">
                        <Skeleton tw="w-full" />
                      </tr>
                      <tr>
                        <Skeleton tw="w-full" />
                      </tr>
                      <tr>
                        <Skeleton tw="w-full" />
                      </tr>
                    </table>
                    <div className="col-md-6">
                      <div tw="mt-1">
                        <Skeleton tw="w-5/12" />
                        <Skeleton tw="w-5/12 my-4" />
                        <Skeleton tw="w-5/12" />
                      </div>
                    </div>
                  </div>
                  <div className="py-2">
                    <div tw="mt-1">
                      <Skeleton tw="uppercase w-3/4 " />
                    </div>
                  </div>
                  <div tw="text-green-600 py-1">
                    <h6>
                      <Skeleton tw="w-5/12" />
                    </h6>
                  </div>
                  <p tw="text-sm">
                    <Skeleton tw="w-6/12" />
                  </p>
                  <Skeleton tw="w-4/12 my-3" />
                  <table className="table table-bordered">
                    <Skeleton count={3} tw="w-3/4" />
                  </table>
                  <div className="row">
                    <Skeleton count={3} tw="w-2/4" />
                  </div>
                  <Skeleton tw="w-1/4 h-1/12 mb-20" />
                </div>
              </>
            )}
          </div>
          <a
            href={`https://wa.me/${branch.alternateMobile}?text=I am interested to buy this product *Tag No : ${Details.productCode} , Branch - ${Details.branchName}*`}
            className="scroll-to-top position-fixed  bottom-0 end-0 "
            tw="mx-6 my-20"
            target="_blank"
            rel="noreferrer"
          >
            <div
              className="rounded-circle text-white p-1"
              style={{ cursor: "pointer", backgroundColor: "#25d366" }}
            >
              <i
                className="bi bi-whatsapp px-2 fs-2"
                style={{ fontSize: "11px" }}
              ></i>
            </div>
          </a>
        </div>
      </section>
      <RelatedProduct Details={Details} />
    </StyledDiv>
  );
};
