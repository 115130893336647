import React, { Suspense } from "react";
import tw from "twin.macro";

const Carousel = React.lazy(() =>
  import(
    "./components/Carousel.js"
  )
);
const ProductCategory = React.lazy(() =>
  import(
    "./components/ProductCategory.js"
  )
);
const GenderBased = React.lazy(() =>
  import(
    "./components/GenderBasedProduct.js"
  )
);
const ProductType = React.lazy(() =>
  import(
    "./components/ProductType"
  )
);
const ArrivalsLiked = React.lazy(() =>
  import(
    "components/ArrivalsLiked.js"
  )
);
const SocialMedia = React.lazy(() =>
  import(
    "components/SocialMedia.js"
  )
);
const BestSelling = React.lazy(() =>
  import(
    "components/BestSelling.js"
  )
);
const TrendOnShops = React.lazy(() =>
  import(
    "components/TrendOnShops.js"
  )
);

const ShopOurExclusives = React.lazy(() =>
  import(
    "components/ShopOurExclusives.js"
  )
);
const OccationCategories = React.lazy(() =>
  import(
    "components/OccasionCategories.js"
  )
);

const SecondBanner = React.lazy(() => 
  import ("components/SecondBanner.js")
)

const Container = tw.div`relative overflow-hidden`;
export default () => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */



  
  return (
    <Container>
      <Suspense fallback={<>Loading...</>}>
        <Carousel />
      </Suspense>
      <Suspense fallback={<>Loading...</>}>
        <ProductType />
      </Suspense>
      <Suspense fallback={<>Loading...</>}>
      <SecondBanner/>
      </Suspense>
      <Suspense fallback={<>Loading...</>}>
        <ProductCategory />
      </Suspense>
      <Suspense fallback={<>Loading...</>}>
        <BestSelling />
      </Suspense>
      <Suspense fallback={<>Loading...</>}>
        <TrendOnShops />
      </Suspense>
      <Suspense fallback={<>Loading...</>}>
        <ShopOurExclusives />
      </Suspense>
      <Suspense fallback={<>Loading...</>}>
        <OccationCategories />
      </Suspense>
      <Suspense fallback={<>Loading...</>}>
        <ArrivalsLiked />
      </Suspense>
      <Suspense fallback={<>Loading...</>}>
        {/* <GenderBased /> */}
      </Suspense>
      <Suspense fallback={<>Loading...</>}>
        <SocialMedia />
      </Suspense>
    </Container>
  );
};
