import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import signupBg from "images/login/signupBg.jpg";
import Divider from "../images/MyScheme/divider.png";
import { useCartContext } from "context/UseCartContext";
import { getBranchId } from "utility/HeaderConfig";
import { generateotp, getOtp, validateOtp } from "api/OtpApi";
import {
  createUser,
  CustomerAutoSearch,
  getUserAccount,
} from "api/CustomerApi";
import { getAllRole } from "api/RoleApi";
import toast from "react-hot-toast";
import { currentRegister } from "utility/Constant";

//eslint-disable-next-line
const Heading = tw.h1`text-center`;
const InputContainer = tw.div`my-5`;

function UserAccountModal(props) {
  const [Loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [emptyField, setEmptyField] = useState(false);
  const [mobileNo, setMobileNo] = useState("");
  const [password, setPassword] = useState("");
  const [MobileInvalid, setMobileInvalid] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState("");
  const [enterOtp, setEnterOtp] = useState({
    id: "",
    username: "",
    accountId: "",
    otpCode: "",
    rolesList: "",
  });
  const [email, setEmail] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const [checkOtp, setCheckOtp] = useState(false);
  const [invalidOTP, setInvalidOTP] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);


  const { dispatch } = useCartContext();


  const getAllRoles = () => {
    setLoading(true);
    const filterData = {
      name: "Customer",
    };
    const response = getAllRole(filterData);
    response.then((data) => {
      if (data.response.length > 0) {
        createAcc(data.response[0].id);
      }
    });
  };

  const createAcc = (roleIds) => {
      const branch = getBranchId();
      const crateData = {
        username: enterOtp.username,
        password: password,
        rolesList: [roleIds],
        email: email !== "" ? email : null,
        branch: branch,
        employeeId: null,
      };
      const response = createUser(crateData);
      response
      .then((data) => {
        toast.success("User Account created SucessFully", {
          position: "top-right",
          duration: 1500,
        });
          setLoading(false);
          dispatch({ type: "MODAL", payload: 'login' })
        })
        .catch((error) => {
          console.error(error);
        })     
    };

  const submitForm = (e) => {
    setIsDisabled(!isDisabled);
    e.preventDefault();
    postAjaxRequest();
  };
  const postAjaxRequest = async () => {
    const mobileReg = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    // Invalid message validate //
    if (!mobileReg.test(mobileNo) && mobileNo !== "") {
      setMobileInvalid(true);
    } else {
      setMobileInvalid(false);
    }

    const validateField = () => {
      if (mobileNo && password && mobileReg.test(mobileNo)) {
        return true;
      } else {
        setEmptyField(true);
        return false;
      }
    };

    if (validateField()) {
      const loginDetails = {
        username: mobileNo,
      };
      const customerSearch = `mobile=${mobileNo}&beforeCreatedAt=${currentRegister()}`;
      let userAccountRes = await getUserAccount(JSON.stringify(loginDetails));
      const customerRes = await CustomerAutoSearch(customerSearch, true);
      if (
        userAccountRes.response &&
        userAccountRes.response.content.length === 0 &&
        customerRes.response &&
        customerRes.response.length > 0
      ) {
        let verifyNumber = "";
        verifyNumber = await generateotp(JSON.stringify(loginDetails));
        if (verifyNumber !== null) {
          const otpNumberResData = await getOtp(verifyNumber.response);
          if (otpNumberResData && otpNumberResData.response.code) {
            let resetPassword = {
              id: otpNumberResData.response.id,
              username: mobileNo,
              accountId: userAccountRes && userAccountRes.response.content.length > 0 ? userAccountRes.response.content[0].id : [],
              otpCode: otpNumberResData.response.code,
            };
            setOtpCode(otpNumberResData.response.code);
            setEnterOtp({ ...enterOtp, ...resetPassword });
          }
          
        } else if (
          userAccountRes.response &&
          userAccountRes.response.content.length > 0
        ) {
          toast.error("User already exists! Please Login", {
            position: "top-right",
            duration: 1500,
          });
        } else if (customerRes.response && customerRes.response.length === 0) {
          toast.error("Customer not available!", {
            position: "top-right",
            duration: 1500,
          });
        }
      } else if (
        userAccountRes.response &&
        userAccountRes.response.content.length > 0
      ) {
        toast.error("User already exists! Please Login", {
          position: "top-right",
          duration: 1500,
        });
      } else if (customerRes.response && customerRes.response.length === 0) {
        toast.error("Customer not available!", {
          position: "top-right",
          duration: 1500,
        });
      }
    }
  };
  useEffect(() => {
    validOTP();
    //eslint-disable-next-line
  }, [enterOtp]);

  const validOTP = () => {
    let otpValid = /^(\+\d{1,3}[- ]?)?\d{6}$/;
    if (otpValid.test(enterOtp.otpCode) && enterOtp.otpCode !== "") {
      validateOtp(enterOtp.id, enterOtp.otpCode).then((data) => {
        if (data.response === true) {
          setCheckOtp(true);
          setInvalidOTP(false);
        }
      });
    } else {
      setInvalidOTP(true);
    }
  };
  const close = () => {
    dispatch({ type: "MODAL", payload: false });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      tw="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div
        tw="shadow-lg relative fixed flex bg-white outline-none focus:outline-none rounded-lg"
        className="col-11 col-sm-11 col-md-10 col-lg-8 col-xl-7  m-sm-0 "
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <div className="d-none d-md-block col-sm-5">
          <img
            src={signupBg}
            className="w-100 h-100"
            tw="rounded-l-lg"
            alt="signup"
          />
        </div>
        <div className="col-12 col-md-7 ">
          <div tw="p-2 py-5  sm:p-5 xl:p-10">
            <div tw="uppercase tracking-wide text-xl sm:text-3xl text-black font-semibold text-center">
              Create New User Account
              <img src={Divider} alt="heading-divider" tw="m-auto mt-2 w-1/4" />
            </div>
            <form>
              <InputContainer>
                <div tw="relative">
                  {(emptyField && mobileNo === "") ||
                  MobileInvalid ||
                  passwordInvalid ? (
                    <div tw="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                      <i class="bi bi-exclamation-circle"></i>
                    </div>
                  ) : (
                    ""
                  )}
                  <div tw="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <i className="bi bi-phone" tw="text-xl"></i>
                  </div>
                  <input
                    type="tel"
                    pattern="[0-9]{10}"
                    id="mobile"
                    placeholder="Enter Your Registered Mobile Number"
                    value={mobileNo}
                    disabled = {isDisabled}
                   onChange={(e) => {
                      const digit = e.target.value.replace(/[^0-9]/g, "");
                      if (digit.length <= 10) {
                        setMobileNo(digit);
                      }
                    }}
                    tw="focus:outline-none focus:border-custom-100
                  focus:ring-1   bg-gray-200 border border-gray-300 
                  text-gray-900 text-sm rounded-lg  block w-full px-10 py-4 tracking-wide"
                    className={`${
                      (emptyField && mobileNo === "") ||
                      MobileInvalid ||
                      passwordInvalid
                        ? "border border-danger"
                        : ""
                    }`}
                  />
                </div>
                {emptyField && mobileNo === "" ? (
                      <p tw="text-red-500 mt-1 mx-5 text-sm">
                        Enter Your Mobile Number
                      </p>
                    ) : (
                      ""
                    )}
               {MobileInvalid && mobileNo !== "" ? (
                      <p tw="text-red-500 mt-1 mb-0 mx-5 text-sm">
                        Mobile Number Field is Invalid
                      </p>
                    ) : (
                      ""
                    )}
              </InputContainer>
              <InputContainer>
                <div tw="relative">
                  <div tw="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <i className="bi bi-file-lock2" tw="text-xl"></i>
                  </div>
                  <input
                    tw="focus:outline-none focus:border-custom-100
                      focus:ring-1   bg-gray-200 border border-gray-300 
                      text-gray-900 text-sm rounded-lg  block w-full px-10 py-4 tracking-wide"
                    type={`${showPassword ? "text" : "password"}`}
                    id="password"
                    disabled = {isDisabled}
                    placeholder="Enter Your password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    className={`${
                      (emptyField && password === "") || passwordInvalid
                        ? "border border-danger"
                        : ""
                    }`}
                  />
                  {(emptyField && password === "") || passwordInvalid ? (
                       <div tw="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                         <i class="bi bi-exclamation-circle"></i>
                       </div>
                     ) : (
                       ""
                     )}
                  <div
                        onClick={() => setShowPassword(!showPassword)}
                        tw="absolute inset-y-0 right-0 flex items-center pr-3"
                      >
                     {showPassword ? (<i class="bi bi-eye"></i>) : (<i class="bi bi-eye-slash"></i>)}
                      </div>
                    </div>
                    {emptyField && password === "" ? (
                      <p tw="text-red-500 mt-1 mx-5 text-sm">
                        Enter Your Password
                      </p>
                    ) : (
                      ""
                    )}
              </InputContainer>
              <InputContainer>
                <div tw="relative">
                  <div tw="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <i className="bi bi-envelope" tw="text-xl"></i>
                  </div>
                  <input
                    type="email"
                    id="e-mail"
                    disabled = {isDisabled}
                    onChange={(e) => {
                      setEmail(e.target.value.trim());
                    }}
                    tw="focus:outline-none focus:border-custom-100
                            focus:ring-1   bg-gray-200 border border-gray-300 
                            text-gray-900 text-sm rounded-lg  block w-full px-10 py-4 tracking-wide"
                    placeholder="Enter Your Email"
                  />
                </div>
              </InputContainer>

              {otpCode && (
                <InputContainer>
                  <div tw="relative">
                    <div tw="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <i className="bi bi-envelope" tw="text-xl"></i>
                    </div>
                    <input
                    disabled
                      type="text"
                      id="opt"
                      tw="focus:outline-none focus:border-custom-100
                            focus:ring-1   bg-gray-200 border border-gray-300 
                            text-gray-900 text-sm rounded-lg  block w-full px-10 py-4 tracking-wide"
                      placeholder="Enter Your OTP"
                      value={otpCode}
                      onChange={(e) => setOtpCode(e.target.value)}
                    />
                  </div>
                </InputContainer>
              )}

              {!otpCode ? (
                <button
                  type="submit"
                  tw="mt-1 transition w-full text-white  bg-custom-100 active:bg-custom-100 tracking-wider font-bold focus:outline-none   rounded-lg text-base py-3 text-center uppercase"
                  onClick={submitForm}
                  disabled={Loading ? true : ""}
                  className={`${Loading ? "bg-secondary" : ""} btn2`}
                >
                  {Loading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="mx-2">Loading . . .</span>
                    </>
                  ) : (
                    <>verify</>
                  )}
                </button>
              ) : (
                <button
                  type="button"
                  tw="mt-1 transition w-full text-white  bg-custom-100 active:bg-custom-100 tracking-wider font-bold focus:outline-none   rounded-lg text-base py-3 text-center uppercase"
                  onClick={getAllRoles}
                  disabled={Loading}
                  className={`${Loading ? "bg-secondary" : ""} btn2`}
                >
                  {Loading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="mx-2">Loading . . .</span>
                    </>
                  ) : (
                    <>Create User Account</>
                  )}
                </button>
              )}
            </form>
          </div>
        </div>
        <div className="position-absolute top-0 end-0" onClick={close}>
          <i
            className="bi bi-x m-2"
            tw="text-3xl text-gray-600 cursor-pointer hover:text-custom-100"
          ></i>
        </div>
      </div>
    </motion.div>
  );
}

export default UserAccountModal;
