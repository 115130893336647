import React, { useState } from "react";
import tw from "twin.macro";
import { isMobile } from 'react-device-detect';
import TelephoneInput from 'react-telephone-input';

export const NavLink = tw.a`no-underline text-black hover:bg-[#faeae2] p-2 hover:text-black block  hover:(-translate-y-2 bg-custom-200) duration-300 transition ease-in-out delay-150 hover:shadow-lg`;

export default function CallSupport() {
  const [isOpen, setIsOpen] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");  

    const numbers = [
      { label: "Gold", number: "9543300006" },
      { label: "Silver", number: "9080512246" },
      { label: "Chit", number: "9092120022" },
    ];

  // const handleClick = () => {
  //   setIsOpen(true);
  //   console.log("Opened")
  // };
  
  // const handleClick = (number) => {
  //   setPhoneNumber(number);
  //   if (window.matchMedia("(max-width: 425px)").matches || isMobile) {
  //     // Mobile device detected, open dialer app
  //     window.location.href = `tel:${number}`;
  //   } else {
  //     // Desktop device detected, show alert box
  //     setIsOpen(true);
  //   }
  // };

  const handleClick = (number) => {
    if (window.matchMedia("(max-width: 425px)").matches || isMobile) {
      // Mobile device detected, open dialer app
      <TelephoneInput
        value={number}
        onChange={() => {}}
        onClick={() => {
          window.location.href = `tel:${number}`;
        }}
      />;
      console.log(number,"number");
    } else {
      console.log(number,"website")
      // Desktop device detected, show alert box
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
          <div tw="mr-[5px] flex items-center justify-center sm:justify-between text-xs sm:text-sm lg:text-xs xl:text-sm relative cursor-pointer">
          <i className="bi bi-phone"></i>
            {numbers.map((item, index) => (
              <span key={index} onClick={() => handleClick(item.number)}>
                {item.label} - {item.number}
                {index < numbers.length - 1 && "|"}
              </span>
            ))}
          </div>
          {isOpen && (
            <div tw="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div tw="bg-white rounded-lg p-4 w-1/4 flex flex-col items-center -mt-40">
                <p tw="text-lg mb-4">Calls are not supported on website.</p>
                <button
                  className="rounded-pill shadow-sm"
                  tw="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                  onClick={handleClose}
                >
                  OK
                </button>
              </div>
            </div>
          )}
        </div>
  );
}
