import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg/svg-decorator-blob-7.svg";
import Noimg from "../images/no_img.jpg";
import { getProduct, orderStatus } from "api/ProductApi";
import { currentRegister, filterFormatToDate } from "utility/Constant.js";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  deleteCustomerLike,
  createCustomerLike,
  createCustomerWish,
  deleteCustomerWish,
} from "api/Like&WishCountApi";
import { getBranchId, getCustomerId } from "utility/HeaderConfig";
import { getPageProductType } from "api/ProductTypeApi";
import { getAllProductSubCate } from "api/ProductSubCateApi";
import { getAllProductCate } from "api/ProductCategoryApi";
import { genderBasedIcons } from "components/GenderBasedIcons";
import no_data from "images/no_data.png";
import SkeletonLoader from "components/SkeletonLoader";
import { dayClose } from "../api/NewSchemeApi";
import moment from "moment";
import SEO from "components/headers/Seo";
import { useCartContext } from "context/UseCartContext";


const Container = tw.div`relative overflow-hidden`;
const TabContent = tw(motion.div)`block `;
const CardContainer = tw.div`mt-4 w-full relative`;
const Card = tw(
  motion.div
)`bg-white block mx-auto  sm:mx-0 no-underline hover:shadow-2xl rounded-lg duration-500 cursor-pointer `;
const CardImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`bg-center bg-cover relative h-80 border border-gray-400 border-solid rounded-t-lg `}
`;
const CardText = tw.div`p-4 pb-1 text-gray-900 bg-gray-200 border border-gray-400 border-solid rounded-b-lg`;
const CardTitle = tw.h5`text-sm group-hover:text-yellow-600 no-underline  text-gray-600 lowercase`;
const CardContent = tw.p`mt-1 mb-2 text-sm font-medium text-custom-100  uppercase`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-custom-100`}
`;
const CardRating = styled.div`
  ${tw` text-sm font-normal flex items-center justify-center  text-base text-gray-600 cursor-pointer rounded-full p-2 border-2 border-gray-400 border-dotted  bg-white`}
`;


export default ({ configImages }) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const location = useLocation();
  const myparam = location.state;
  const history = useNavigate();

  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(true);
  const [listLoading, setListLoading] = useState(true);
  const [size, setSize] = useState(21);
  const [productType, setProductType] = useState([]);
  const [productSubCate, setProductSubCate] = useState([]);
  const [productCategory, setCategoryList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [dayClosedDates, setDayClosedDates] = useState("");
  const [orderStatusSale, setOrderStatusSale] = useState([]);
  const [orderStatusTransfer, setOrderStatusTransfer] = useState([]);
  const [orderStatusSold, setOrderStatusSold] = useState([]);
  const [likeState, setLikeState] = useState({});
  const [wishState, setWishState] = useState({});
  const [selectedList, setSelectedList] = useState({
    type: "",
    category: "",
    subCategory: "",
    gender: "",
    // price: "",
    minWt: '',
    maxWt: '',
    status: 'available'
  });
  //eslint-disable-next-line
  const [lists, setLists] = useState([
    "gold", "silver", "diamond", "platinum"
  ])
  const { dispatch } = useCartContext();


  //initial product page click call
  // useEffect(() => {
  //   if (dayClosedDates !== "") {
  //     getProductList(dayClosedDates);
  //   } else {
  //     dayClose({
  //       module: "Sales",
  //       branchId: getBranchId(),
  //     }).then((data) => {
  //       if (data.response.length > 0) {
  //         let dateString = data.response[0].activeDate;
  //         let date = moment(dateString, "MMM DD, YYYY, h:mm:ss A");
  //         let dayCloseDate = date.format('YYYY-MM-DD');
  //         getProductList(dayCloseDate)
  //       }
  //     });
  //   }
  //   //eslint-disable-next-line
  // }, [orderStatusSale])

  useEffect(() => {
    getProductType()
    setSelectedList({
      ...selectedList,
      type: myparam && myparam.type ? myparam.type.id : "",
      category:
        myparam && myparam.category
          ? myparam.category.id
          : selectedList.category,
      subCategory: "",
      typeName: myparam && myparam.type ? myparam.type.name : "",
      categoryName:
        myparam && myparam.category
          ? myparam.category.categoryName
          : selectedList.categoryName,
      subCategoryName: "",
      genderName: "",
      // priceName: "",
      minWtName: "",
      maxWtName: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myparam]);

  useEffect(() => {
    getCategoryList();
    getProductSub();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    Promise.all([
      getOrderStatus(),
    ]).then((result) => {
      if (myparam?.category) {
        getProducts(orderStatusSale, orderStatusTransfer, orderStatusSold)
        setSelectedList({
          ...selectedList,
          type: myparam && myparam.category.metaProdType ? myparam.category.metaProdTypeId : "",
          category: myparam && myparam.category.categoryName ? myparam.category.id : selectedList.category,
          subCategory: "",
          typeName: myparam && myparam.category.metaProdType ? myparam.category.metaProdType : "",
          categoryName: myparam && myparam.category.categoryName ? myparam.categoryName : selectedList.category,
          subCategoryName: "",
          genderName: "",
          // priceName: "",
          minWtName: "",
          maxWtName: "",
        });
      }
      if (myparam?.type) {
        setSelectedList({
          ...selectedList,
          type: myparam.type.id,
          category: "",
          subCategory: "",
          typeName: myparam.type.name,
          categoryName: "",
          subCategoryName: "",
          // price: "",
          // priceName: "",
          minWt: "",
          minWtName: "",
          maxWt: "",
          maxWtName: "",
        });
      }
      setOrderStatusSale(orderStatusSale)
      setOrderStatusTransfer(orderStatusTransfer)
      setOrderStatusSold(orderStatusSold)
    })
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getOrderStatus();
    if (
      selectedList.type ||
      selectedList.category ||
      selectedList.subCategory ||
      selectedList.gender ||
      // selectedList.price ||
      selectedList.minWt ||
      selectedList.maxWt ||
      selectedList.status
    ) {
      // size
      dayClose({
        module: "Sales",
        branchId: getBranchId(),
      }).then((data) => {
        if (data.response.length > 0) {
          let dateString = data.response[0].activeDate;
          let date = moment(dateString, "MMM DD, YYYY, h:mm:ss A");
          let dayCloseDate = date.format("YYYY-MM-DD");
          getProductList(dayCloseDate);
        }
      });
    }
    //eslint-disable-next-line
  }, [selectedList, size]);

  const getProducts = (orderStatusSale, orderStatusTransfer, orderStatusSold) => {
    let filterData = {
      allowCatalogue: 1,
      beforeCreatedAt: currentRegister(),
      demoStatus: "Demo_Completed,Demo_Cancelled,Available_For_Demo",
      metaProdCateEnty: myparam && myparam.category.categoryName ? myparam.category.id : selectedList.category,
      metaProdTypeEnty: myparam && myparam.category.metaProdType ? myparam.category.metaProdTypeId : '',
      removeHp: "HP-",
      removeNTP: "NTP-",
      sortType: true,
      statusId: `${orderStatusSale}, ${orderStatusTransfer}, ${orderStatusSold}`,
      sort: "asc",
    }
    getProduct(size, filterData)
      .then((data) => {
        setProductList(data.response.content);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getOrderStatus = async () => {
    try {
      const data = await orderStatus({ multiOrderStatus: "sale,Transferred,Sold" });

      if (!data || !data.response) {
        console.error("Invalid API response:", data);
        return;
      }
      const statusResponse = data.response;
      const saleData = [];
      const transferData = [];
      const soldData = [];

      statusResponse.forEach((item) => {
        const status = item.orderStatusName.toLowerCase();
        if (status === "sale") {
          saleData.push(item.id);
        } else if (status === "transferred") {
          transferData.push(item.id);
        } else if (status === "sold") {
          soldData.push(item.id);
        }
      });

      setOrderStatusSale(saleData);
      setOrderStatusTransfer(transferData);
      setOrderStatusSold(soldData);
    } catch (err) {
      console.error("API Error:", err);
    }
  };

  const getProductType = () => {
    let pList = [];
    setListLoading(true);
    getPageProductType({ active: 1 }).then((data) => {
      setListLoading(true);
      let response = data.response.content;
      pList = response.filter((data) => data.metaProductCategoryList.length > 0 && data.id)
      pList.reverse();
      setProductType(pList);
      setListLoading(false);
    });
  };

  const getCategoryList = () => {
    setListLoading(true);
    let visibilityList = [];
    let filterData = {
      metaProdTypeEnty: selectedList.type ? selectedList.type : "",
      // visibilityMode: "WEB_MOBILE,MOBILE",
    };
    if (filterData.metaProdTypeEnty === "") {
      delete filterData.metaProdTypeEnty;
    }
    getAllProductCate(filterData).then((data) => {
      const productResponse = data.response;
      visibilityList = productResponse.filter(item => item.visibilityMode === 'WEB_MOBILE' || item.visibilityMode === 'MOBILE')
      setCategoryList(visibilityList);
      setListLoading(false);
    });
  };

  const getProductSub = () => {
    setListLoading(true);
    let filterData = {
      metaProdTypeEnty: selectedList.type ? selectedList.type : "",
      metaProdCateEnty: selectedList.category ? selectedList.category : "",
    };
    if (filterData.metaProdTypeEnty === "") {
      delete filterData.metaProdTypeEnty;
    }
    if (filterData.metaProdCateEnty === "") {
      delete filterData.metaProdCateEnty;
    }
    getAllProductSubCate(filterData).then((data) => {
      let response = data.response;
      setProductSubCate(response);
      setListLoading(false);
    });
  };

  // const handleChange = (e) => {
  //   let target = e.target;
  //   let input = target.name;
  //   let value = target.value;
  //   let index = e.nativeEvent.target.selectedIndex;
  //   let text = e.nativeEvent.target[index]?.text;

  //   setSelectedList((prevState) => ({
  //     ...prevState,
  //     [input]: value,
  //     [`${input}Name`]: text, // Only add a name field if applicable
  //   }));
  // };

  const handleChange = (e) => {
    let target = e.target;
    let input = target.name;
    let value = target.value;
    let index = e.nativeEvent.target.selectedIndex;
    let text = e.nativeEvent.target[index]?.text;

    if (input === "type") {
      setSelectedList({
        ...selectedList,
        type: value,
        category: "",
        subCategory: "",
        typeName: text,
        categoryName: "",
        subCategoryName: "",
      });
    } else if (input === "category") {
      setSelectedList({
        ...selectedList,
        category: value,
        subCategory: "",
        categoryName: text,
        subCategoryName: "",
      });
    } else if (input === "subCategory") {
      setSelectedList({
        ...selectedList,
        subCategory: value,
        subCategoryName: text,
      });
    } else if (input === "gender") {
      setSelectedList({
        ...selectedList,
        gender: value,
        genderName: text,
      });
    } else if (input === "status") {
      setSelectedList({
        ...selectedList,
        status: value,
        statusName: text,
      });
    } 
    // else if (input === "price") {
    //   setSelectedList({
    //     ...selectedList,
    //     price: value,
    //     priceName: text,
    //   })
    // } 
    else if (input === "grossMinWt") {
      setSelectedList({
        ...selectedList,
        minWt: value,
        minWtName: text,
      })
    } else if (input === "grossMaxWt") {
      setSelectedList({
        ...selectedList,
        maxWt: value,
        maxWtName: text,
      })
    }
    //  else if (input === "sortBy") {
    //   setSelectedList({
    //     ...selectedList,
    //     sortBy: value,
    //     sortByName: text,
    //   })
    // }
  };


  //eslint-disable-next-line
  const dayCloseDay = () => {
    dayClose({
      module: "Sales",
      branchId: getBranchId(),
    }).then((data) => {
      if (data.response.length > 0) {
        let dateString = data.response[0].activeDate;
        let date = moment(dateString, "MMM DD, YYYY, h:mm:ss A");
        let dayCloseDate = date.format('YYYY-MM-DD');
        setDayClosedDates(dayCloseDate);
      }
    });
  };


  const getProductList = (dayCloseDate) => {
    setLoading(true);
    let filterData = {
      removeNTP: "NTP-",
      removeHP: "HP-",
      beforeCreatedAt: filterFormatToDate(dayCloseDate),
      branch: getBranchId(),
      metaProdTypeEnty: selectedList.type || "",
      metaProdCateEnty: selectedList.category || "",
      productSubcategory: selectedList.subCategory || "",
      userType: selectedList.gender || "",
      // totalAmtFrom: selectedList.price.split('-')[0] === 'below' ? '' : Number(selectedList.price.split('-')[0]),
      // totalAmtTo: selectedList.price.split('-')[1] === 'above' ? '' : Number(selectedList.price.split('-')[1]),
      grossWeightFrom: selectedList.minWt ? Number(selectedList.minWt) : null,
      grossWeightTo: selectedList.maxWt ? Number(selectedList.maxWt) : null,
      statusId:
        selectedList.status === "allStatus"
          ? `${orderStatusSale},${orderStatusTransfer},${orderStatusSold}`
          : `${orderStatusSale},${orderStatusTransfer}`,
      allowCatalogue: 1,
      productDemoStatus: "Available_For_Demo,Demo_Completed,Demo_Cancelled",
    };



    Object.keys(filterData).forEach((key) => {
      if (filterData[key] === "") delete filterData[key];
    });
    // if (selectedList.price === '') {
    //   delete filterData.totalAmtFrom
    //   delete filterData.totalAmtTo
    // }
    if (selectedList.minWt === '') {
      delete filterData.grossWeightFrom
    }
    if (selectedList.maxWt === '') {
      delete filterData.grossWeightTo
    }
    if (
      orderStatusSale.length > 0 &&
      orderStatusTransfer.length > 0 &&
      orderStatusSold.length > 0
    ) {
      getProduct(size, filterData)
        .then((data) => {
          if (typeof data.response === "string") {
            let parsedResponse = JSON.parse(data.response);
            setProductList(parsedResponse.content);
          } else {
            setProductList(data.response.content);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.error("Product API Error:", err);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const resetAll = () => {
    setSelectedList({
      type: "",
      category: "",
      subCategory: "",
      gender: "",
      typeName: "",
      categoryName: "",
      subCategoryName: "",
      genderName: "",
      // price: "",
      // priceName: "",
      minWt: "",
      minWtName: "",
      maxWt: "",
      maxWtName: "",
    });
  };

  useEffect(() => {
    getBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBanner = () => {
    configImages && configImages.E_COMMERCE_BANNERS.forEach((data) => {
      if (data.key === "productList") {
        setBanner(data.value);
      }
    });
  };

  const wishCountFunc = (product) => {
    if (getCustomerId() && getCustomerId() !== "onlinepayment") {
      const productId = product.productId ? product.productId : product.id;
      setWishState((prevState) => ({
        ...prevState,
        [productId]: !prevState[productId], // Toggle only for the clicked product
      }));

      if (wishState[productId]) {
        const deletWish = `customerId=${getCustomerId()}&productId=${productId}`;
        deleteCustomerWish(deletWish);
      } else {
        const createWish = `customerId=${getCustomerId()}&productId=${productId}&remark=liked`;
        createCustomerWish(createWish);
      }
    } else {
      dispatch({ type: "MODAL", payload: "login" });
    }
  };


  const likeCountFunc = (product) => {
    if (getCustomerId() && getCustomerId() !== "onlinepayment") {
      const productId = product.productId ? product.productId : product.id;
      setLikeState((prevState) => ({
        ...prevState,
        [productId]: !prevState[productId], // Toggle only for the clicked product
      }));

      if (likeState[productId]) {
        const deletLike = `customerId=${getCustomerId()}&productId=${productId}`;
        deleteCustomerLike(deletLike);
      } else {
        const createLike = `customerId=${getCustomerId()}&productId=${productId}&remark=liked`;
        createCustomerLike(createLike);
      }
    } else {
      dispatch({ type: "MODAL", payload: "login" });
    }
  };

  const handleOpenDetails = (categoryItem) => {
    window.open(`/ProductDetails/${categoryItem.id}`, "_blank")
  }

  return (
    <Container>
      <SEO
        title='ProductList - AMR Sumangalee Jewellers'
        description='Beginner friendly page for learning React Helmet.'
        name='Company name.'
        type='article' />
      {/* {banner && banner.length > 0 ? (
        <img src={banner} alt="Jewellery Product List " className="w-100" />
      ) : (
        <Skeleton tw="w-full" style={{ height: "200px" }} />
      )} */}
      {/* <div className="ptSans px-sm-5 px-0" tw="text-sm pt-3">
        <Link to="/" tw="mx-3 no-underline text-black">
          Home
        </Link>
        /
        <span tw="ml-3 text-custom-100 capitalize tracking-wide">
          {selectedList.typeName && selectedList.typeName
            ? selectedList.typeName
            : selectedList.categoryName && selectedList.categoryName}{" "}
        </span>
      </div> */}
      <div className="container pt-3 pt-sm-5 ptSans">
        <div className="row flex-wrap align-items-center">
          <div className="col-md-1 d-md-none  d-lg-none d-xl-block">
            <span>Filter By :</span>
          </div>
          {/* <div className="mb-2 col-md-6 col-lg-2 py-sm-0 py-2">
            <select
              className="selectpicker form-select"
              id="style-11"
              tw="px-4 py-2 focus:outline-none focus:border-custom-100 focus:ring-1 uppercase"
              onFocus={getProductType}
              name="type"
              onChange={handleChange}
              value={selectedList.type}
            >
              <option hidden className="text-uppercase">
                METAL TYPE
              </option>
              {productType.length === 0 ? (
                <option className="text-lowercase" tw="text-lg">
                  No Data
                </option>
              ) : (
                productType.map((data) => (
                  <option key={data.id} value={data.id} tw="uppercase">
                    {data.name}
                  </option>
                ))
              )}
              {/* {listLoading && (
                <option className="text-lowercase">Loading...</option>
              )} */}
          {/* </select>
          </div>  */}
          {/* <div className="mb-2 col-md-6 col-lg-2 py-sm-0 py-2">
            <select
              className="selectpicker form-select"
              tw="px-4 py-2 focus:outline-none focus:border-custom-100 focus:ring-1 uppercase"
              onFocus={getCategoryList}
              name="category"
              onChange={handleChange}
              id="style-11"
              value={selectedList.category}
            >
              <option hidden className="text-uppercase">
                CATEGORY
              </option>
              {productCategory.length === 0 ? (
                <option className="text-lowercase" tw="text-lg">
                  No Data
                </option>
              ) : (
                productCategory.map((data) => (
                  <option
                    value={data.id}
                    tw="uppercase"
                  // className={`${listLoading && "d-none"}`}
                  >
                    {data.categoryName}
                  </option>
                ))
              )}
              {/* {listLoading && (
                <option className="text-lowercase">loading...</option>
              )} */}
          {/* </select>
          </div>  */}
          {/* <div className="mb-2 col-md-5 col-lg-3 py-sm-0 py-2">
            <select
              className="selectpicker form-select"
              tw="px-4 py-2 focus:outline-none focus:border-custom-100 focus:ring-1 uppercase"
              onFocus={getProductSub}
              name="subCategory"
              onChange={handleChange}
              id="style-11"
              value={selectedList.subCategory}
            >
              <option hidden className="text-uppercase">
                SUBCATEGORY
              </option>
              {productSubCate.length === 0 ? (
                <option className="text-lowercase" tw="text-lg">
                  No Data
                </option>
              ) : (
                productSubCate.map((data) => (
                  <option
                    value={data.id}
                    tw="uppercase"
                  // className={`${listLoading && "d-none"}`}
                  >
                    {data.subCategoryName}
                  </option>
                ))
              )}
              {/* {listLoading && (
                <option className="text-lowercase">loading...</option>
              )} */}
          {/* </select>
          </div>  */}
          {/* <div className=" col-md-6 col-lg-3 col-xl-2  mb-2 py-sm-0 py-2">
            <select
              className="selectpicker form-select"
              tw="px-4 py-2 focus:outline-none focus:border-custom-100 focus:ring-1"
              name="gender"
              onChange={handleChange}
              id="style-11"
              value={selectedList.gender}
            >
              <option hidden className="text-uppercase">
                GENDER
              </option>
              <option className="my-2" value="A">
                ALL
              </option>
              {genderBasedIcons.map((data, index) => (
                <option className="my-2" key={index} value={data.gender}>
                  {data.title}
                </option>
              ))}
            </select>
          </div> */}
          <div className=" col-md-6 col-lg-3 col-xl-2  mb-2 py-sm-0 py-2">
            <select
              className="selectpicker form-select"
              tw="px-4 py-2 focus:outline-none focus:border-custom-100 focus:ring-1"
              name="status"
              onChange={handleChange}
              id="style-11"
              value={selectedList.status}
            >
              <option className="my-2" value="available">
                AVAILABLE
              </option>
              <option className="my-2" value="allStatus">
                ALL ITEMS
              </option>
            </select>
          </div>
          {/* <div className="mb-2  col-lg-2 py-sm-0 py-2 ml-[110px]"
          // style={{ marginLeft: "110px" }}
          >
            <select
              className="form-select selectpicker"
              tw=" py-2 focus:outline-none focus:border-custom-100 focus:ring-1 uppercase"
              name="price"
              //  id="style-11"
              onChange={handleChange}
              value={selectedList.price}
            >
              <option value="">Price Range</option>
              <option value="below-1000">Below 1K</option>
              <option value="5000-10000">5K - 10K</option>
              <option value="10000-15000">10K - 15K</option>
              <option value="15000-25000">15K - 25K</option>
              <option value="25000-above">25K & Above</option>
            </select>
          </div> */}
          <div className="mb-2 col-md-5 col-lg-3 py-sm-0 py-2">
            <input
              type="number"
              className="form-control"
              name="grossMinWt"
              onChange={handleChange}
              value={selectedList.minWt}
              placeholder="Min Weight"
            />
          </div>
          <div className="mb-2 col-md-5 col-lg-3 py-sm-0 py-2">
            <input
              type="number"
              className="form-control"
              name="grossMaxWt"
              onChange={handleChange}
              value={selectedList.maxWt}
              placeholder="Max Weight"
            />
          </div>
        </div>
      </div>
      {(selectedList.type ||
        selectedList.category ||
        selectedList.subCategory ||
        selectedList.gender ||
        // selectedList.price ||
        selectedList.minWt ||
        selectedList.maxWt ||
        selectedList.status) && (
          <div className="container ">
            <div className="px-sm-4 px-2 py-2 py-sm-3 mt-3 mx-0 border rounded row align-items-center w-100">
              <span className="ptSans col-md-3 col-lg-3 col-xl-2  px-md-2 d-none d-lg-block">
                Currently Shopping by :
              </span>
              <div className="d-flex justify-content-between align-items-center col-md-12 p-0 col-lg-9 col-xl-10">
                <ul className="d-flex align-items-center m-0 p-0 ptSans flex-wrap">
                  {selectedList.typeName && (
                    <li className="item amshopby-item mx-2 mb-1">
                      <div
                        className="amshopby-filter-value text-black"
                        tw="tracking-wide uppercase"
                      >
                        {selectedList.typeName}
                      </div>
                      <span
                        className="amshopby-remove "
                        tw="cursor-pointer"
                        onClick={() =>
                          setSelectedList({
                            ...selectedList,
                            type: "",
                            typeName: "",
                          })
                        }
                      >
                        <i
                          className="bi bi-x-circle-fill ms-2 text-secondary"
                          tw="text-base"
                        ></i>
                      </span>
                    </li>
                  )}
                  {selectedList.categoryName && (
                    <li className="item amshopby-item mx-2 mb-1">
                      <div
                        className="amshopby-filter-value text-black"
                        tw="tracking-wide uppercase"
                      >
                        {selectedList.categoryName}
                      </div>
                      <span
                        className="amshopby-remove "
                        tw="cursor-pointer"
                        onClick={() =>
                          setSelectedList({
                            ...selectedList,
                            category: "",
                            categoryName: "",
                          })
                        }
                      >
                        <i
                          className="bi bi-x-circle-fill ms-2 text-secondary"
                          tw="text-base"
                        ></i>
                      </span>
                    </li>
                  )}
                  {selectedList.subCategoryName && (
                    <li className="item amshopby-item mx-2 mb-1">
                      <div
                        className="amshopby-filter-value text-black"
                        tw="tracking-wide uppercase"
                      >
                        {selectedList.subCategoryName}
                      </div>
                      <span
                        className="amshopby-remove "
                        tw="cursor-pointer"
                        onClick={() =>
                          setSelectedList({
                            ...selectedList,
                            subCategory: "",
                            subCategoryName: "",
                          })
                        }
                      >
                        <i
                          className="bi bi-x-circle-fill ms-2 text-secondary"
                          tw="text-base"
                        ></i>
                      </span>
                    </li>
                  )}
                  {selectedList.genderName && (
                    <li className="item amshopby-item mx-2 mb-1">
                      <div
                        className="amshopby-filter-value text-black"
                        tw="tracking-wide uppercase"
                      >
                        {selectedList.genderName}
                      </div>
                      <span
                        className="amshopby-remove "
                        tw="cursor-pointer"
                        onClick={() =>
                          setSelectedList({
                            ...selectedList,
                            gender: "",
                            genderName: "",
                          })
                        }
                      >
                        <i
                          className="bi bi-x-circle-fill ms-2 text-secondary"
                          tw="text-base"
                        ></i>
                      </span>
                    </li>
                  )}
                  {/* {selectedList.priceName && (
                    <li className="item amshopby-item mx-2 mb-1">
                      <div
                        className="amshopby-filter-value text-black"
                        tw="tracking-wide uppercase"
                      >
                        {selectedList.priceName}
                      </div>
                      <span
                        className="amshopby-remove "
                        tw="cursor-pointer"
                        onClick={() =>
                          setSelectedList({
                            ...selectedList,
                            price: "",
                            priceName: "",
                          })
                        }
                      >
                        <i
                          className="bi bi-x-circle-fill ms-2 text-secondary"
                          tw="text-base"
                        ></i>
                      </span>
                    </li>
                  )} */}
                  {selectedList.minWt && selectedList.maxWt && (
                    <li className="item amshopby-item mx-2 mb-1">
                      <div className="amshopby-filter-value text-black" tw="tracking-wide uppercase">
                        {selectedList.minWt}-{selectedList.maxWt}
                      </div>
                      <span
                        className="amshopby-remove"
                        tw="cursor-pointer"
                        onClick={() =>
                          setSelectedList({
                            ...selectedList,
                            minWt: "",
                            maxWt: "",
                          })
                        }
                      >
                        <i className="bi bi-x-circle-fill ms-2 text-secondary" tw="text-base"></i>
                      </span>
                    </li>
                  )}

                  {selectedList.status && (
                    <li className="item amshopby-item mx-2 mb-1">
                      <div
                        className="amshopby-filter-value text-black"
                        tw="tracking-wide uppercase"
                      >
                        {selectedList.status}
                      </div>
                      <span
                        className="amshopby-remove "
                        tw="cursor-pointer"
                        onClick={() =>
                          setSelectedList({
                            ...selectedList,
                            status: "",
                            status: "",
                          })
                        }
                      >
                        <i
                          className="bi bi-x-circle-fill ms-2 text-secondary"
                          tw="text-base"
                        ></i>
                      </span>
                    </li>
                  )}
                </ul>
                <span
                  tw="text-custom-100 sm:text-base text-sm  font-semibold  cursor-pointer text-center"
                  onClick={resetAll}
                >
                  Clear all
                </span>
              </div>
            </div>
          </div>
        )}
      <div className="pt-sm-3 pt-0 pb-5 mb-5 position-relative">
        <div className="container container-influid-md">
          {productList && productList.length === 0 && !loading ? (
            <img src={no_data} alt="no-data" className="col-md-5 m-auto" />
          ) : (
            <div className="row">
              {productList && productList.map((categoryItem, index) => (
                <TabContent
                  key={index}
                  transition={{ duration: 0.4 }}
                  className="col-md-6 col-lg-4 col-xl-3"
                >
                  <CardContainer>
                    <div tw="flex leading-none absolute inline-flex top-0 right-0 m-2  z-20" className="z-1">
                      <CardRating
                        className={`${likeState[categoryItem.id] ? "hand" : ""} bi bi-hand-thumbs-up mx-3 tooltip1`}
                        onClick={() => likeCountFunc(categoryItem)}
                      >

                        {categoryItem.likesCount !== 0 && <span className="tooltiptext">
                          {categoryItem.likesCount !== null && categoryItem.likesCount !== "-" && categoryItem.likesCount !== "" && categoryItem.likesCount !== 0
                            ? categoryItem.likesCount
                            : ""}
                        </span>}
                      </CardRating>
                      <CardRating
                        className={`${wishState[categoryItem.id] ? "like" : ""} bi bi-heart tooltip1`}
                        onClick={() => wishCountFunc(categoryItem)}
                      >

                        {categoryItem.wishListCount !== 0 && <span className="tooltiptext">
                          {categoryItem.wishListCount !== null && categoryItem.wishListCount !== "-" && categoryItem.wishListCount !== "" && categoryItem.wishListCount !== 0
                            ? categoryItem.wishListCount
                            : ""}
                        </span>}
                      </CardRating>
                    </div>
                    {/* <ProductCommon categoryItem={categoryItem} /> */}
                    <Card
                      className="group"
                      initial="rest"
                      whileHover="hover"
                      animate="rest"
                      onClick={() =>
                        handleOpenDetails(categoryItem)
                        // history(`/ProductDetails/${categoryItem.id}`, { state: { categoryItem } })
                      }
                    >
                      <div className="overflow-hidden">
                        <CardImageContainer
                          imageSrc={
                            categoryItem.images === "@#@"
                              ? Noimg
                              : categoryItem.images.split("@#@")[0]
                          }
                          tw="transform transition duration-500  group-hover:(scale-125 rotate-12)"
                        ></CardImageContainer>
                        <div
                          className="badge"
                          tw="absolute  top-0 left-0 m-3 bg-yellow-600 p-1"
                        >
                          {categoryItem.stockStatus === "Sale" && "Available"}
                        </div>
                      </div>
                      <CardText>
                        <div className="d-flex justify-content-between mb-1 align-items-center">
                          <div className="col-md-7">
                            <CardTitle className="capitalize">
                              {categoryItem.metalType}
                            </CardTitle>
                            <CardContent
                              className="capitalize"
                              tw="truncate w-full "
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={categoryItem.title}
                            >
                              {categoryItem.title}
                            </CardContent>
                          </div>
                          <div className="col-md-5 text-end">
                            {categoryItem.stockStatus.toLowerCase() === 'sold' ? '' : (
                              <b tw="text-lg flex justify-end tracking-wide">
                                <span className="mx-1">&#8377;</span>{" "}
                                {Math.trunc(categoryItem.total)}
                              </b>
                            )}
                            {categoryItem.goldPurity !== "-" && (
                              <small tw="text-xs">
                                {categoryItem.goldPurity}
                              </small>
                            )}
                          </div>
                        </div>
                      </CardText>
                    </Card>
                  </CardContainer>
                </TabContent>
              ))}
              <DecoratorBlob1 />
              <DecoratorBlob2 />
            </div>
          )}
          {loading && <SkeletonLoader />}
          <div className="d-flex justify-content-center pt-4 mt-3">
            {productList && productList.length !== 0 && (
              <div
                type="button"
                tw="border border-custom-100 duration-300 hover:text-white hover:bg-custom-100 px-20 py-2  text-sm"
                onClick={() => setSize(size + 3)}
              >
                {loading ? "Loading ..." : "View More"}
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};